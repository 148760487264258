import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery

// CSS
import '@tabler/core/dist/css/tabler.min.css'
import '@tabler/core/dist/css/tabler-flags.min.css'
import '../styles/application.css'
import '../styles/custom.css'
import 'selectize/dist/css/selectize.css'
import 'trix/dist/trix.css'

// JS
import 'selectize'
import '@tabler/core'
import 'microplugin'
import 'sifter'
import 'trix'
import * as Popper from '@popperjs/core'
import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

// Globals
window.Popper = Popper
window.html2canvas = html2canvas
window.jsPDF = jsPDF
